import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:55245/";
  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public check_Unicod: boolean = false;


  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";


  public key_value="";

  public Intrest_Format="Flat"

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:55245/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = true;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;

  public  mobile_menu: boolean = false;

  
  public from_customer_page:boolean=false;
  public W_height = 0;
  public W_width = 0;

  public SM_Row = [];
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  
  public Loan_Issue_Page="/collection/emi-loan-issue";
  public Add_Receipt_Page="/transaction/add-receipt-entry-two";
  public U_Rights=""
  public Item_Group_ = "1";
  public isadd_Page:boolean=false;
  lenth: number = 0;
  public GST_Code = "33";
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;
      this.Company_ID = this.data.UM_Company;
      this.GST_Code=this.data.CM_State_Code;
    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.M_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");


    this.exp_Date = this.datePipe.transform(new Date(), "MM/yy");
    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.Sal_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.Expense_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');



  }

  public lable_ = "";
  public item = "";
  public Filter_Type = "contain";

  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public Company_ID = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Edit_Row1: any = {};

  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";

  public disp_category:boolean=false;
  public img_visible: boolean = false;

  
  public Date;
  public Date1;
  public From_Date;
  public To_Date;
  public Expense_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public FS_Date = "2021-04-01";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Calibration_Standard ="";
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";


  public Customer_Route="";
  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";

  public Default_Payment_ID: string = "Cash";
  public Default_Bank_ID: string = "0";
  public Pay_Date="";
  public Sal_Date="";
  public exp_Date="";
  public Open_Customer_Total = 0;
  public Open_Customer_Length = 0;
  public DB_Expense_Total=0;
  public Emi_Loan_Issue_Total=0;
  public DB_Bank_Row=0;
  public DB_Bank_Total=0;
  Cash_ID = "CASH";
  Cheque_ID = "Cheque";
  Card_ID = "Card";
  VEH = "Maintanance";
  Default_Veh_ID="";
  public check_Unicode: boolean = false;
  public Stock_Check: boolean = true;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;
  public cart_item = [

  ];

  Clear_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
    } catch { }

  }

  public Item_Group="";
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];

  public Stock_Report = [

    { Menu: 'Current Stock', Link: '/report/current-stock', class: '' },
    { Menu: 'Category Stock', Link: '/report/Categorywise-stock', class: '' },
    { Menu: 'Itemwise Stock', Link: '/report/itemwise-stock', class: '' },
    { Menu: 'Stock Report', Link: '/report/stock-report', class: '' }
  ];
  
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];
  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
  public  To_Amt="0";
  public  Ledger_Amt="0";
get_CR_DB_Amount(data)
{
  this.get("Api/Transaction/get_CR_DB_Amount?Ledger_ID=" +data).subscribe((res: any) => {
   this.Ledger_Amt= res;
  });
 this.Default_Bank_ID=data;
}

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  F_Row = [];
  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
    });
  }

  Product_Rows = [];
  public isEdit: boolean = false;
  public load_from_make_invoice: boolean = false;



  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";
  public Menus_Rows: any = {};
  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {

    
    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }
  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  loading = false;


  Num(data) {
    return Number(data).toFixed(2);
  }



  public filter_data="";
  pdfType = "Open";


  get(url) {
    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }


  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";
  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }


  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }

  public Bank_Master_Rows = [];
  get_Bank_Master() {

    this.getc("Api/Master/get_Bank_Master").subscribe((res: any) => {
      this.Bank_Master_Rows = JSON.parse(res).record;
    });
  }


  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }

  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  export_excel1() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel1?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  //loading = false;

  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  File_Name = "Data";

  export_pdf() {
    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  get_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  Lable_Order_entry="Order Entry";
  Lable_Takeaway="Takeaway";
  Lable_Dining="Dining";
  Lable_Purchase = "Purchase";
  Lable_Sales = "Sales";
  Lable_Receipt = "Receipt";
  Lable_Payment = "Payment";
  Lable_Expence = "Expence";
  Lable_Daybook = "Day Book";
  Lable_Receivable = "Receivable";
  Lable_Payable = "Payable";
  Lable_Item_Master = "Item Master";
  Lable_Ledger = "LedgerMaster";
  Lable_Reports = "Reports";
  Lable_Minimum_Stock = "Minimum Stock";

  public dashboard_data() {
    this.get_Branch_Master()
    this.get_Item_Master();
    this.get_vehicle_master();
    this.get_SLedger_Master();
    this.get_Ledger_Master();
    this.get_Bank_Master();
    this.get_Ledger_Group();
  }
  public DF_Bill_Type = "Tax Invoice";

  open_pdf(data) {
  }

  open_purchase_pdf(data) {
    window.open(this.Server_URL + "report/Purchase_Order?PO_No=" + data.po_no + "&Company=" + this.Company, "_blank");
  }

  public DF_Paymode = "244";
  public ledger_count = 0;
  public Minimum_Stock_count=0;
  public DB_Vadi_Total = 0;
  public DB_Sales_Date_Diff_= 0;



  public Item_Order_by = " pm_item_name asc";
  public Product_Master_Rows = [];
  public Product_Master_col_GF = ['pm_brand','pm_category', 'pm_item_name','pm_gst_per','pm_mrpprice','pm_purprice','pm_uom','pm_hsn_code' ];

  get_product_Master(Status) {
      
    this.get("Api/Master/get_product_Master?Status=" + Status + "&vType=Product&Order_by="+this.Item_Order_by).subscribe((res: any) => {
      
      this.Product_Master_Rows = JSON.parse(res).record;

    });
  }


  public Vehicle_Master_Rows = [];
  public Vehicle_details="";
  public Vehicle_Master_col_GF = ['veh_vehicleno', 'veh_type', 'veh_make', 'veh_model', 'veh_insurance', 'veh_fcdate', 'veh_permitdate', 'Veh_Date', 'Veh_CreatedBy'];

  public Order_by=" right(veh_vehicleno,3) asc";
  get_vehicle_master() {
      
    this.get("Api/Master/get_vehicle_master?Status=" + this.Status+"&Order_by="+this.Order_by).subscribe((res: any) => {
      
      this.Vehicle_Master_Rows = JSON.parse(res).record;

    });
  }


  public Customer_Master_Export = [];
public Cus_Type="ALL";
public Status="A";
public Route="ALL";
public cus_order_by=" cast(cus_code as int) asc";
public Customer_Order_by = " cus_name asc";
public Ledger_Rows1 = [];
public Ledger_Master_Rows = [];
public Customer_Master_col_GF = ['cus_code','cus_name', 'cus_contactperson','cus_area', 'cus_contactno', 'cus_gstin', 'cus_state', 'cus_created_by'];
 
get_SLedger_Master() {
this.isload = true;
  this.get("Api/Master/get_Ledger_Master?Status="+this.Status+"&Type="+this.Cus_Type+"&Route="+this.Route+"&Order_by="+this.cus_order_by).subscribe((res: any) => {
    
    this.isload = false;
    this.Ledger_Master_Rows = JSON.parse(res).record;
    this.Ledger_Rows1 = JSON.parse(res).record;
  });
}

public Ledger_Group = [];
public Ledger_Group_ = [];
get_Ledger_Group() {
  this.isload = true;
  this.Ledger_Group = [];

  this.getc("Api/Master/get_Ledger_Group").subscribe((res: any) => {
    this.Ledger_Group = JSON.parse(res).record;
    this.DF_Group_ID=this.Ledger_Group[0].value;
    this.DF_Group_Type=this.Ledger_Group[0].label;
    this.Ledger_Group_ = this.get_ref(this.DF_Group_Type);
    this.isload = false;
    console.log()
  });

}


public Group_Member_Name = [];
get_Group_Member_Name(data) {

  this.isload = true;
  this.Group_Member_Name = [];
  this.get("Api/Collection_/get_Group_Member1?Group_Code="+data).subscribe((res: any) => {
  this.Group_Member_Name = JSON.parse(res).record;

   this.isload = false;

  });

}
public Grp_Customer_type=[];
public Group_Master_GF = ['Group_Name','Ledger_Name','Remarks'];
public DF_Group_ID="";
public DF_Group_Type="";
public Ledger_Master_GF = [];
public Ledger_Row: any = {};
public Ledger_Master_Export = [];
onChange(data)
{
  this.Ledger_Group_ = this.get_ref(this.Ledger_Group.filter(e=>e.value==data)[0].label);
  this.Ledger_Row=this.PLedger_Master_Rows.filter(e=>e.Group_ID==data);

  this.DF_Group_Type=this.Ledger_Group.filter(e=>e.value==data)[0].label;
  this.DF_Group_ID=data;
}

ledger_Filter() {

  this.SLedger_Rows = this.PLedger_Master_Rows.filter(e =>this.Grp_Customer_type.includes(e.Group_ID));


}
public Ledger_Order_by = " cus_id ";
public PLedger_Master_Rows = [];
public SLedger_Rows = [];
public LedgerLoad: boolean = false;

  get_Ledger_Master() {
    this.LedgerLoad = true;
 
    this.isload = true;
    
    this.get("Api/Master/get_Ledger_Master1?Order=" + this.Ledger_Order_by + "&Group_ID=All").subscribe((res: any) => {
      this.isload = false;
      this.LedgerLoad = false;
      
      this.PLedger_Master_Rows = JSON.parse(res).record;
      this.onChange(this.DF_Group_ID)
      // this.Bank_Category = this.Ledger_Master_Rows.filter(e =>this.Grp_Bank_type.includes(e.Group_ID));
      // this.Income_Category = this.Ledger_Master_Rows.filter(e =>this.Grp_Income_type.includes(e.Group_ID));

      this.ledger_Filter();
      try {
        this.ledger_count = this.PLedger_Master_Rows.length;
      } catch { }
    });
  }


  public PLedger_Rows = [];
  public D_Group_Type="";
  get_Group_ledger(data) {

    var Ledger_Name = [...new Set(this.PLedger_Master_Rows.filter(e => e.cus_category == data).map(item => item.cus_ledger_type))];
    this.PLedger_Rows = [];
    this.D_Group_Type = this.Reference_Rows.filter(e => e.value == data)[0].label;
    for (let data2 of Ledger_Name) {
      if(data2=="Master")
      {
        if(this.D_Group_Type=="Supplier")
        {
          this.PLedger_Rows=this.PLedger_Master_Rows.filter(e => e.cus_type == "Supplier");
        }
        else if(this.D_Group_Type=="Customer"){
        this.PLedger_Rows=this.PLedger_Master_Rows.filter(e => e.cus_type == "Customer");
        }
        else if(this.D_Group_Type=="Employee"){
        this.PLedger_Rows=this.PLedger_Master_Rows.filter(e => e.cus_type == "Employee");
        }
        else
        {
          this.PLedger_Rows=this.Vehicle_Master_Rows;

        }
      }
      else
      {
        this.PLedger_Rows=this.PLedger_Master_Rows.filter(e => e.cus_category == data);
      }
    }

    return this.PLedger_Rows;
  }


public Ledger_="0";
public Shift_Type="ALL";
public Ledger_Route="ALL";

public RC_Bill_Amt_Check: boolean = true;
public Due_Amt=0;
public M_From="";
Payment_Total_Amt = 0;
Ledger_Payment_Row = [];
get_Ledger_Payment(data) {
  this.isload = true;
  this.Payment_Total_Amt = 0;

  this.get("Api/Transaction/get_Ledger_Paid_Details?From=" + this.S_From + "&To=" + this.S_To+"&Ledger="+data).subscribe((res: any) => {
    this.Ledger_Payment_Row = JSON.parse(res).record;
    this.Payment_Total_Amt = (this.Ledger_Payment_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
    this.isload = false;

  });

}


public Area_Row = [];
  public S_customer = "0"
public Customerwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
public Supplierwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];

public Outstand_Amt = 0;
public Outstanding_Rows = [];
public selected_Out_customer: any = {}
public selected_Out_supplier: any = {}
public DB_Customer_Total = 0;
public Billwise_Out = [];
public Customerwise_Out = [];
public Areawise_Out = [];
public Streetwise_Out = [];
public Sales_Personwise_Out = [];
public Duedays_Out = [];
public DB_Customer_lenth=0;
public select_mode="Pending";
get_OutStanding() {
  this.isload = true;
  this.DB_Customer_Total = 0;
  this.get("Api/Invoice/get_Outstanding?Date=" + this.S_To  + "&Area=" + this.S_Area +"&Area_Map=" + this.Area_Map + "&Rights=" + this.Rights_Name + "&User_ID=" + this.Current_User.UM_ID + "&Order_by=Bill_Date").subscribe((res: any) => {
    this.isload = false;
    if(res!="[]")
    {
   
    this.Outstanding_Rows = JSON.parse(res).record;

    this.Billwise_Out = [];
    var Bill_No = [...new Set(this.Outstanding_Rows.map(item => item.Bill_No))];
    for (let data of Bill_No) {
      var sum = (this.Outstanding_Rows.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var rs = this.Outstanding_Rows.filter(e => e.Bill_No == data);

      this.Billwise_Out.push({ 'Bill_No': data, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });

    }




    this.Customerwise_Out = [];
    var Ledger_ID = [...new Set(this.Outstanding_Rows.map(item => item.Ledger_ID))];


 
    for (let data of Ledger_ID) {
      var sum = (this.Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      var count = [...new Set((this.Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;


      var crow = this.Ledger_Master_Rows.filter(e => e.ID == data)[0];


      try {
        if (Number(sum) != 0) {
          this.Customerwise_Out.push({ 'Customer_Name': crow.Ledger_Name, 'Customer_ID': crow.ID, 'Area': crow.Area, 'Street': crow.Street, 'Phone_No': crow.Phone_Number, 'count': count, 'Amount': sum });
        }
      } catch { }
      try {
        this.DB_Customer_lenth = this.Outstanding_Rows.length;

        this.DB_Customer_Total = (this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount),  0)).toFixed(2);
      } catch { }
    }
    }


    this.Areawise_Out = [];
    var Area = [...new Set(this.Outstanding_Rows.map(item => item.Area))];
    for (let data of Area) {
      var sum = (this.Outstanding_Rows.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Outstanding_Rows.filter(e => e.Area == data)).map(item => item.Ledger_ID))].length;

      this.Areawise_Out.push({ 'Area': data, 'count': count, 'Amount': sum });
    }

    this.Streetwise_Out = [];
    var Street = [...new Set(this.Outstanding_Rows.map(item => item.Street))];
    for (let data of Street) {
      var sum = (this.Outstanding_Rows.filter(e => e.Street == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Outstanding_Rows.filter(e => e.Street == data)).map(item => item.Ledger_ID))].length;

      this.Streetwise_Out.push({ 'Street': data, 'count': count, 'Amount': sum });
    }



    this.Sales_Personwise_Out = [];
    var Created_by = [...new Set(this.Outstanding_Rows.map(item => item.Created_by))];
    for (let data of Created_by) {
      var sum = (this.Outstanding_Rows.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Outstanding_Rows.filter(e => e.Created_by == data)).map(item => item.Bill_No))].length;

      this.Sales_Personwise_Out.push({ 'Created_by': data, 'count': count, 'Amount': sum });
    }





    this.Duedays_Out = [];
    var Dues = [...new Set(this.Outstanding_Rows.map(item => item.Dues))];
    for (let data of Dues) {
      var sum = (this.Outstanding_Rows.filter(e => e.Dues == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Outstanding_Rows.filter(e => e.Dues == data)).map(item => item.Bill_No))].length;

      this.Duedays_Out.push({ 'Dues': data, 'count': count, 'Amount': sum });
    }




    try {

      this.Outstand_Amt = (this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


    } catch
    {

    }


  });
}
public DB_payable = 0;
public DB_Due_Days_payable;
public DB_Due_Days_payable_=0;;

public Payable_Outstand_Amt = [];
public Supplierwise_Out = [];
public Billwise_Payable_Out = [];
public Payable_Outstanding_Rows = [];
get_Payable_OutStanding() {
  this.isload = true;
  this.DB_payable = 0;
  this.DB_Due_Days_payable_ = 0;

  this.get("Api/transaction/get_Purchase_outstanding?Date=" + this.S_To  + "&Customer=0&Area=" + this.S_Area + "&Type=" + this.select_mode + "&Order_by=Bill_Date").subscribe((res: any) => {

    this.Payable_Outstanding_Rows = JSON.parse(res).record;
    this.isload = false;

    this.Billwise_Payable_Out = [];
    var Purchase_No = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Purchase_No))];
    for (let data of Purchase_No) {
      var sum = (this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var rs = this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data);

      this.Billwise_Payable_Out.push({ 'Bill_No': rs[0].Bill_No, 'Purchase_No': data, 'Bill_Date': rs[0].Bill_Date_,  'Due_Date': rs[0].Due_Date_,'Customer_Name': rs[0].Customer_Name,'Due_Days': rs[0].Due_Days, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });
    }
    for(var i=0;i<this.Billwise_Payable_Out.length;i++)
      {
      if(this.Billwise_Payable_Out[i]["Due_Days"]=="0")
      {
        this.DB_Due_Days_payable_=this.DB_Due_Days_payable_+1;
      }
      
      }
    
    this.Supplierwise_Out = [];
    var Ledger_ID = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Ledger_ID))];


    console.log(Ledger_ID);
    for (let data of Ledger_ID) {
      var sum = (this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      var count = [...new Set((this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;

      var crow = this.Ledger_Master_Rows.filter(e => e.cus_id == data)[0];
      var rs = this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data);


      try {
        if (Number(sum) != 0) {
          this.Supplierwise_Out.push({ 'Customer_Name': crow.cus_name, 'Customer_ID': crow.cus_id, 'Area': crow.cus_area, 'Street': crow.cus_area, 'Phone_No': crow.Phone_Number, 'Bill_No': rs[0].Bill_No, 'count': count, 'Amount': sum });
        }
      } catch { }
    }
    try {

      this.Payable_Outstand_Amt = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


    } catch
    {

    }
    try {

      this.DB_payable = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0));


    } catch
    {

    }



  });

}

onChange_Company(data)
{
  this.Company = "_"+data;
  var rs =this.Branch_Master_Rows.filter(e => e.value == data);
  this.Current_User.CM_ID=rs[0].CM_ID;
  this.Current_User.CM_Name=rs[0].CM_Name;
  this.Current_User.CM_Address1=rs[0].CM_Address1;
  this.Current_User.CM_Address3=rs[0].CM_Address3;
  this.Current_User.CM_Address4=rs[0].CM_Address4;
  this.Current_User.CM_Address5=rs[0].CM_Address5;
  this.Current_User.CM_Email_ID=rs[0].CM_Email_ID;
  this.Current_User.CM_GST_No=rs[0].CM_GST_No;
  this.Current_User.CM_State_Code=rs[0].CM_State_Code;
  this.Current_User.CM_State=rs[0].CM_State;
  this.Current_User.CM_Pan_No=rs[0].CM_Pan_No;
  this.Current_User.CM_Phone_off=rs[0].CM_Phone_off;
  this.Current_User.CM_Phone_Res=rs[0].CM_Phone_Res;
}

public Branch_Master_Rows = [];
get_Branch_Master()
{
  this.getc("Api/Common/Get_Branch_Master").subscribe((res:any )=>{
       
    this.Branch_Master_Rows=JSON.parse(res).record;
     });
}


public Receipt_Row = [];
public Receipt_Row_Total = 0;
public Receipt_Row_Cout = 0;
public DB_Receipt = 0;
get_Recipts() {
  this.isload = true;
  this.Receipt_Row_Total = 0;
  this.DB_Receipt = 0;

  this.get("Api/Transaction/get_Collection_Details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
    this.Receipt_Row = JSON.parse(res).record;
    this.isload = false;
    try {

      this.Receipt_Row_Total = (this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      this.Receipt_Row_Cout = this.Receipt_Row.length;
    } catch { }
    try {
      this.DB_Receipt = this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0);
    } catch { }

  });

}

public Receipt_AMT=0;
public Ledger_Receipt_Rows=[]
get_Ledger_Recipts(data) {
  this.isload = true;

  this.get("Api/Transaction/get_Receipt_Details?From=" + this.S_From + "&To=" + this.S_To+"&Ledger="+data).subscribe((res: any) => {
    this.Ledger_Receipt_Rows = JSON.parse(res).record;
    this.isload = false;
    this.Receipt_AMT = (this.Ledger_Receipt_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

  });

}


public DB_Payment = 0;
public Payment_type = "Purchase";
public Payment_Vtype = "Payment";
Payment_Details_Row = [];
Payment_Row_Total = 0;
Payment_Row_Cout = 0;
get_Payment() {
  this.isload = true;
  this.DB_Payment = 0;
  this.get("Api/Transaction/get_Paid_Details?From=" + this.S_From + "&To=" + this.S_To + "&Type=" + this.Payment_type+ "&VType=" + this.Payment_Vtype).subscribe((res: any) => {
    this.Payment_Details_Row = JSON.parse(res).record;
    this.isload = false;
    try {

      this.Payment_Row_Total = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      this.Payment_Row_Cout = this.Payment_Details_Row.length;
    } catch
    {

    }
    try {

      this.DB_Payment = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0));
    } catch
    {

    }
  });

}



Balance_Details_Row = [];
// Payment_Row_Total = 0;
// Payment_Row_Cout = 0;
get_Balance() {
  this.isload = true;
  this.get("Api/Transaction/get_Opening_Details?From=" + this.S_From + "&To=" + this.S_To + "&Type=" + this.Payment_type).subscribe((res: any) => {
    this.Balance_Details_Row = JSON.parse(res).record;
    this.isload = false;
    
  });

}

get_Ledger_group(data)
{
    return  this.Ledger_Master_Rows.filter(e=>e.Group_ID==data);
}
public Biller_Rows = [];


public Item_Row_Cout = 0;
public Item_Master_Rows = [];
public Item_Orderby_Name = "Category,Item_Name";
public Perment_Item_Master_Row = [];
public Reset_Item_Master_Row = [];
public Item_Master_RM_Rows=[];
public item_Category_ = "";
public Item_Category = [];


public RM_all_item:boolean=true;
get_Item_Master() {

  this.loading = true;
  this.isload = true;
  this.Item_Master_Rows = [];
  this.get("Api/Master/get_Item_Master?Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
    this.Item_Master_Rows = JSON.parse(res).record;

    
  });
}

public Customer_Search: boolean = true;
public Supplierwise_Track_GF = ['Supplier', 'S_Value', 'Profit', 'Stock_Value','P_Value'];
public Purchase_Export = [];

public Purchase_Item_Rowdata = "";
public Purchase_Date = "";
public Purchase_Customer: any = {};
public Purchase_Item_Row = [];
public Purchasewise_Details() {
this.isload = true;
this.Purchase_Item_Row = [];
this.get("Api/Transaction/get_Purchasewise_Item?ID=" + this.Purchase_Item_Rowdata + "&From=" + this.S_From + "&To=" + this.S_To+ "&order_by=pur_bill_date desc").subscribe((res: any) => {
this.Purchase_Item_Row = JSON.parse(res).record;
this.Purchase_Customer = this.Purchase_Item_Row[0];
    this.isload = false;

  });

}

public Purchase_Order_Item_Row = [];
public Purchasewise_Order_Details() {
  this.isload = true;
  this.Purchase_Order_Item_Row = [];
  this.get("Api/Transaction/get_Purchasewise_Item?ID=" + this.Purchase_Item_Rowdata + "&From=" + this.S_From + "&To=" + this.S_To+ "&order_by=pur_bill_date desc").subscribe((res: any) => {
  this.Purchase_Order_Item_Row = JSON.parse(res).record;
  this.Purchase_Customer = this.Purchase_Order_Item_Row[0];
      this.isload = false;
  
    });
  
  }

public Purchase_Order_GF = [];
public Purchase_Order_Export = [];
public Purchase_Order_Row = [];
get_Purchase_Order_Details() {
  this.isload = true;
  this.Purchase_Order_Row = [];
  
  this.get("Api/Transaction/get_Purchase_Order_Details?From=" + this.S_From  + "&To=" + this.S_To + "&User=" + this.Search_User + "&Type=" + this.Purchase_Type + "&order_by=po_no").subscribe((res: any) => {
    
    this.isload = false;
    this.Purchase_Order_Row = JSON.parse(res).record;
    
    console.log(this.Purchase_Order_Row);

  });

}

public Purchase_Type="Purchase";

public header_Row: any = {}
public Details_Row = [];
public Purchase_Master_GF = [];
public DB_Purchase_Total = 0;
public Purchase_Row = [];
get_Purchase_Details() {
  this.isload = true;
  this.DB_Purchase_Total = 0;
  this.Purchase_Row = [];

  this.get("Api/Transaction/get_Purchase_Detail?From=" + this.S_From  + "&To=" + this.S_To + "&User=" + this.Search_User + "&Type=" + this.Purchase_Type + "&order_by=pur_no").subscribe((res: any) => {
    this.Purchase_Row = JSON.parse(res).record;
    this.isload = false;
    try {
      this.DB_Purchase_Total = this.Purchase_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
    } catch { }

  });

}


public Sales_Temp_Row: any = {};




public cr = 0;
public db = 0;
public bal = 0;

public Milk_Accounts_GF = ['Nar_Type', 'Created_by', 'Pay_Mode_', 'CR_AMT'];
public Total = 0;
public Rows = [];
public Milk_Accounts_Row = [];
public get_Milk_Accounts() {
  this.isload = true;
  this.Milk_Accounts_Row = [];
  this.get("Api/Reports/get_Milk_Accounts?From=" + this.S_From + "&To=" + this.S_To + "&Ledger_ID=" + this.Ledger_ ).subscribe((res: any) => {
    this.Milk_Accounts_Row = JSON.parse(res).record;
    this.isload = false;
    this.Total = 0;
    this.cr = (this.Milk_Accounts_Row.reduce((sum, current) => sum + parseFloat(current.Credit), 0)).toFixed(2);
    this.db = (this.Milk_Accounts_Row.reduce((sum, current) => sum + parseFloat(current.Debit), 0)).toFixed(2);
    this.bal = (this.Milk_Accounts_Row.reduce((sum, current) => sum + parseFloat(current.Balance), 0)).toFixed(2);

    this.Total = this.db - this.cr;

  });

}




public Curent_Stock_Export = [
  { Field: 'Category', Name: 'Category', Align: '' },

  { Field: 'Item_Name', Name: 'Item Name', Align: '' },
  { Field: 'MRP', Name: 'MRP', Align: 'right' },
  { Field: 'Rate', Name: 'Rate', Align: 'right' },
  { Field: 'Stock', Name: 'Stock', Align: 'right' },
  { Field: 'Value', Name: 'Value', Align: 'right' },


];
public Category_Stock_Export = [
  { Field: 'Category', Name: 'Category', Align: '' },

  { Field: 'Stock', Name: 'Stock', Align: 'right' },
  { Field: 'Value', Name: 'Value', Align: 'right' },


];
public Itemwise_Stock_Export = [
  { Field: 'Vour_Type', Name: 'Vour Type', Align: '' },
  { Field: 'Voucher_No', Name: 'Voucher No', Align: '' },
  { Field: 'Voucher_Date_', Name: 'Voucher Date', Align: '' },
  { Field: 'Rate', Name: 'Rate', Align: '' },
  { Field: 'Inward_Qty', Name: 'Inward Qty', Align: '' },
  { Field: 'Outward_Qty', Name: 'Outward Qty', Align: 'right' },
  { Field: 'Balance', Name: 'Balance', Align: 'right' },


];

public Current_stock_GF = ['Item_Name',  'Category', 'Rate', 'Stock', 'Value','MRP'];
public I_Category = "All";
public I_Brand = "All";
public S_Item_ID = "";
public S_Item_Name = "";
public Category_Stock = [];
public Current_Stock = [];

get_Current_Stock_Details() {
  this.isload = true;
  this.get("Api/Reports/get_Currenct_Stock?From=" + this.S_From + "&To=" + this.S_To +"&Category="+this.I_Category+"&Brand="+this.I_Brand).subscribe((res: any) => {
    
    this.isload = false;
    this.Current_Stock = JSON.parse(res).record;
    this.Category_Stock = [];
    var Category = [...new Set(this.Current_Stock.map(item => item.Category))];
    for (let data of Category) {
      var sum = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock), 0)).toFixed(2);
      var sum2 = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Value), 0)).toFixed(2);
      this.Category_Stock.push({ 'Category': data, 'Stock': sum,'Value': sum2, });
    }



  });

}

public Stock_report_Export = [
  { Field: 'Item_Name', Name: 'Item Name', Align: '' },
  { Field: 'Category', Name: 'Category', Align: '' },
  { Field: 'Rate', Name: 'Rate', Align: '' },
  { Field: 'Opening', Name: 'Opening', Align: '' },
  { Field: 'Inward', Name: 'Inward', Align: '' },
  { Field: 'Outward', Name: 'Outward', Align: 'right' },
  { Field: 'Closing', Name: 'Closing', Align: 'right' }

];
public Stock_report_GF = ['Item_Name', 'Category', 'Opening', 'Rate', 'Inward', 'Outward', 'Closing'];
public Stock_Report_Row = [];
get_Stock_Report() {
  this.isload = true;
  this.get("Api/Reports/get_Stock_Report?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
    this.Stock_Report_Row = JSON.parse(res).record;
    this.isload = false;
  });

}



public Itemwise_stock_GF = ['Vour_Type', 'Voucher_No', 'Voucher_Date_', 'Rate'];
public Item_Stock = [];

get_Item_Stock_Details() {
  this.isload = true;
  this.get("Api/Reports/get_Item_Stock?Item_ID=" + this.S_Item_ID + "&From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
    this.Item_Stock = JSON.parse(res).record;
    this.isload = false;
    try {
      this.Item_Stock[0].Balance = Number(this.Item_Stock[0].Inward_Qty) - Number(this.Item_Stock[0].Outward_Qty);
      for (var i = 1; i < this.Item_Stock.length; i++) {
        this.Item_Stock[i].Balance = (Number(this.Item_Stock[i - 1].Balance)) + (Number(this.Item_Stock[i].Inward_Qty) - Number(this.Item_Stock[i].Outward_Qty));
      }

    }
    catch { }
  });
}



public Purchase_Report = [
  { Menu: 'Billwise', Link: '/report/billwise-purchase', class: '' },
  { Menu: 'Itemwise', Link: '/report/itemwise-purchase', class: '' },
  { Menu: 'Userwise', Link: '/report/userwise-purchase', class: '' },
  { Menu: 'Daywise', Link: '/report/daywise-purchase', class: '' },
  { Menu: 'supplierwise', Link: '/report/supplierwise-purchase', class: '' },
  { Menu: 'Paymodewise', Link: '/report/paymodewise-purchase', class: '' },

];

public Itemwise_Purchase_Export = [
  { Field: 'Item_Name', Name: 'Item Name', Align: '' },
  { Field: 'Qty', Name: 'Quality', Align: '' },
  { Field: 'Amount', Name: 'P Amount', Align: 'right' },
  { Field: 'S_Rate', Name: 'S Amount', Align: 'right' },
  { Field: 'Profit', Name: 'Profit', Align: 'right' }

];

public Billwise_Purchase_Export = [

  { Field: 'Bill_No', Name: 'Bill No', Align: '' },
  { Field: 'Purchase_Date', Name: 'Date', Align: '' },
  { Field: 'Supplier_Name', Name: 'Suppier Name', Align: '' },
  { Field: 'Contact_No', Name: 'Phone No', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }

];

public Userwise_Purchase_Export = [
  { Field: 'User', Name: 'User', Align: '' },
  { Field: 'count', Name: 'Count', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }

];
public Daywise_Purchase_Export = [
  { Field: 'Purchase_Date_', Name: 'Date', Align: '' },
  { Field: 'count', Name: 'Count', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }

];
public Supplierwise_purchase_Export = [
  { Field: 'Supplier_Name', Name: 'Supplier Name', Align: '' },
  { Field: 'count', Name: 'Count', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' },


];
public Paymodewise_Purchase_Export = [
  { Field: 'Pay_Mode', Name: 'Pay Mode ', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }
];


public Billwise_Purchase_GF = ['Supplier_Name', 'Bill_No', 'Purchase_Date', 'Phone_No', 'Amount'];
public Itemwise_Purchase_GF = ['Item_Name', 'Amount', 'Qty'];
public Userwise_Purchase_GF = ['User', 'Amount', 'count'];
public Supplierwise_purchase_GF = ['Supplier_Name', 'count', 'Amount'];
public Paymodewise_Purchase_GF = ['Pay_Mode', 'Amount'];
public Invoice_Row_total = 0;
public Invoice_Row_length = 0;
public Billwise_Purchase_Sum_Row = [];
public Paymodewise_Purchase_Sum_Row = [];
public Date_wise_Purchase_Row = [];
public Supplierwise_Purchase = [];
public userwise_Purchase_Sum_Row = [];
// public Areawise_Purchase_Sum_Row = [];
public Item_wise_purchase_Row = [];
public Itemwise_purchase_Row = [];
get_Purchase_Report() {
  this.isload = true;
  this.Invoice_Row_total = 0;
  this.Invoice_Row_length = 0;
  this.get("Api/Reports/get_Purchase_Report?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&Area=" + this.S_Area  + "&order_by=Purchase_Date desc").subscribe((res: any) => {
    
    this.Itemwise_purchase_Row = JSON.parse(res).record;
    this.isload = false;

    try {

      this.Invoice_Row_total = (this.Itemwise_purchase_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      this.Invoice_Row_length = this.Itemwise_purchase_Row.length;

    } catch
    {

    }



    var Item = [...new Set(this.Itemwise_purchase_Row.map(item => item.Item_Name))];
    this.Item_wise_purchase_Row = [];
    for (let data of Item) {
      var Amount_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      var Qty_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
      var Profit_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
      var S_Rate_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Rate), 0)).toFixed(2);

      this.Item_wise_purchase_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_,Profit:Profit_ ,S_Rate:S_Rate_})
    }


    // this.Areawise_Purchase_Sum_Row = [];
    // var Area = [...new Set(this.Itemwise_purchase_Row.map(item => item.Area))];
    // for (let data of Area) {
    //   var sum = (this.Itemwise_purchase_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
    //   this.Areawise_Purchase_Sum_Row.push({ 'Area': data, 'Amount': sum });
    // }


    this.userwise_Purchase_Sum_Row = [];
    var User = [...new Set(this.Itemwise_purchase_Row.map(item => item.User))];


    for (let data of User) {
      var sum = (this.Itemwise_purchase_Row.filter(e => e.User == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.User == data)).map(item => item.Bill_No))].length;

      this.userwise_Purchase_Sum_Row.push({ 'User': data, 'count': count, 'Amount': sum });
    }


    this.Paymodewise_Purchase_Sum_Row = [];
    var Pay_Mode_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Bill_Mode))];
    for (let data of Pay_Mode_) {
      var sum = (this.Itemwise_purchase_Row.filter(e => e.Bill_Mode == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      this.Paymodewise_Purchase_Sum_Row.push({ 'Pay_Mode': data, 'Amount': sum });
    }



    this.Date_wise_Purchase_Row = [];
    var Purchase_Date_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_Date_))];
    for (let data of Purchase_Date_) {
      var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data)).map(item => item.Purchase_No))].length;

      this.Date_wise_Purchase_Row.push({ 'Purchase_Date_': data, 'count': count, 'Amount': sum });
    }
    this.Supplierwise_Purchase = [];
    var Supplier_Name = [...new Set(this.Itemwise_purchase_Row.map(item => item.Supplier_Name))];
    for (let data of Supplier_Name) {
      var sum = (this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data)).map(item => item.Bill_No))].length;

      this.Supplierwise_Purchase.push({ 'Supplier_Name': data, 'count': count, 'Amount': sum });
    }


    this.Billwise_Purchase_Sum_Row = [];
    var Purchase_No = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_No))];
    for (let data of Purchase_No) {
      var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

      var rs = this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data);

      this.Billwise_Purchase_Sum_Row.push({ 'Bill_No': rs[0].Bill_No, 'Purchase_Date_': rs[0].Purchase_Date, 'Purchase_Date': rs[0].Purchase_Date_, 'Supplier_Name': rs[0].Supplier_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });


    }




  });

}


public Customer_Balance = 0;
public Stock_Entry_Details_GF = ['Customer_Name', 'Bill_No','Bill_Date','Disc_Amt','Sub_Total','Net_Amt','Contact_No'];
public DB_Stock_Entry_Count = 0;

public DB_Stock_Entry_Total = 0;
public Stock_Entry_Row = [];
get_Stock_Entry_Details() {
  //this.isload = true;
  this.DB_Stock_Entry_Total = 0;
  this.get("Api/Transaction/get_Stock_Entry_Detail?From=" + this.S_From + "&To=" + this.S_To +"&User=" + this.Search_User  + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
    this.Stock_Entry_Row = JSON.parse(res).record;
    this.isload = false;

   
    try {
      this.DB_Stock_Entry_Count = this.Stock_Entry_Row.length;

      this.DB_Stock_Entry_Total = this.Stock_Entry_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
    } catch { }

  });

}
public Ledger_Pending_Export = [

  { Field: 'label', Name: 'Bill No', Align: '' },
  { Field: 'Bill_Date', Name: 'Date', Align: '' },
  { Field: 'days', Name: 'Days', Align: '' },
  { Field: 'Net_Amt', Name: 'Bill Amount', Align: 'right' },
  { Field: 'Bill_Amount', Name: 'Due Amount', Align: 'right' }

];
public Sales_Details_GF = ['Customer_Name', 'Bill_No', 'Area'];
public Sales_Export = [];

public DB_Sales_Total = 0;
public Sales_Row = [];
get_Sale_Details() {
  this.isload = true;
  this.DB_Sales_Total = 0;
  this.get("Api/Invoice/get_Sale_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=sal_Bill_Date,sal_Bill_No").subscribe((res: any) => {
    this.Sales_Row = JSON.parse(res).record;
    this.isload = false;
    
    

    for(var i=0;i<this.Sales_Row.length;i++)
      {
        var stringValue="";
        stringValue = this.Sales_Row[i].Net_Amt;
        this.Sales_Row[i].Net_Amt= parseInt(stringValue);
      }

      
    try {
      this.DB_Sales_Total = this.Sales_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
    } catch { }

  });

}


public Sales_Item_Rowdata = "";
public Sales_Date = "";
public Sales_Customer: any = {};

public Sales_Item_Row = [];
public Saleswise_Details() {
  this.isload = true;
  this.Sales_Item_Row = [];
  this.get("Api/Invoice/get_Saleswise_Item?ID=" + this.Sales_Item_Rowdata + "&Bill_Mode=" + this.S_Bill_Mode  + "&From=" + this.S_From + "&To=" + this.S_To + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area).subscribe((res: any) => {
    this.Sales_Item_Row = JSON.parse(res).record;
    this.Sales_Customer = this.Sales_Item_Row[0];
    this.isload = false;

  });

}


public Opening_Entry_Rows;
public Item_Master_GF = ['Category','Item_Name','HSN_Code','GST_Per'];

public Pay_Mode = "";
public Bill_Mode = "Cash";
public Receiving_Bank = "0";
public Cheque_No = "";
public Cheque_Date = this.Today_Date;
public Card_Charge = "";
public Remarks = "";
public pay_Mode_reset() {
  this.Pay_Mode = this.Cash_ID;
  this.Receiving_Bank = "0";
  this.Cheque_No = "";
  this.Cheque_Date = this.Today_Date;
  this.Card_Charge = "";
  this.Remarks = "";

}


public Income_Export = [

  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },

  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_created_by', Name: 'User', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Expense_Export = [

  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },

  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_created_by', Name: 'User', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public S_Exp_Category = "0";

public oc_type="";
public Expense_Details_GF = ['oc_date', 'oc_category', 'oc_ledger_name', 'oc_paymode', 'oc_amount'];
public Exp_Rows = [];
public Exp_Total = 0;
get_expense() {
  this.isload = true;
  this.Exp_Rows = [];
  this.get("Api/Transaction/get_OtherCollection_details?From=" + this.S_From + "&To=" + this.S_To + "&Type=" + this.oc_type ).subscribe((res: any) => {
    this.Exp_Rows = JSON.parse(res).record;
    this.isload = false;
    try {
      this.Exp_Total = 0;
      this.Exp_Total = (this.Exp_Rows.reduce((sum, current) => sum + parseFloat(current.oc_amount), 0)).toFixed(2);
    } catch
    { }
  });

}



public Contra_Export = [

  { Field: 'Ref_No', Name: 'No', Align: '' },
  { Field: 'Ref_Date_', Name: 'Date', Align: '' },
  { Field: 'From_Account_', Name: 'From', Align: '' },
  { Field: 'To_Account_', Name: 'To', Align: '' },

  { Field: 'Naration', Name: 'Description', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }

];

public Contra_Details_GF = [ 'Naration', 'Amount','From_Account','To_Account'];
public Contra_Rows = [];
public Contra_Total = 0;
get_Contra() {
  this.isload = true;
  this.Contra_Rows = [];
  this.get("Api/Transaction/get_Contra?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
    this.Contra_Rows = JSON.parse(res).record;
    this.isload = false;
    try {
      this.Contra_Total = 0;
      this.Contra_Total = (this.Contra_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
    } catch
    { }
  });

}

public ledger_status=[];
public  Ledger_St="";
public  Ledger_Bal="0";
get_Ledger_Amount(data)
{
this.get("Api/Reports/get_customer_balance?Ledger=" +data).subscribe((res: any) => {
 this.ledger_status= JSON.parse(res).record;
 this.Ledger_Bal=this.ledger_status[0].Balance;
 this.Ledger_St=this.ledger_status[0].Status;
});
}


public Vehicle_Maintenance_report_Export = [
  { Field: 'oc_vehicle_no', Name: 'Vehicle No', Align: '' },
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Supplier', Align: '' },
  { Field: 'oc_category', Name: 'Expense', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Vehicle_Maintenance_report_GF = ['oc_vehicle_no', 'oc_date', 'oc_ledger_name', 'oc_category', 'oc_narration1', 'oc_amount'];
public Vehicle_Maintenance_Report_Row = [];


get_Vehicle_Maintenance_Report() {
  this.isload = true;
  this.get("Api/Reports/get_Vehicle_Maintenance_Reports?From=" + this.S_From + "&To=" + this.S_To +"&Type=Vehicle Maintanace").subscribe((res: any) => {
    this.Vehicle_Maintenance_Report_Row = JSON.parse(res).record;
    this.isload = false;
  });
}

public Fuel_Entry_Report_Export = [
  { Field: 'oc_vehicle_no', Name: 'Vehicle No', Align: '' },
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Supplier', Align: '' },
  { Field: 'oc_category', Name: 'Expense', Align: '' },
  { Field: 'oc_nooflit', Name: 'No.of.Ltrs', Align: '' },
  { Field: 'oc_perliter', Name: 'Per.Ltr', Align: '' },
  { Field: 'oc_km', Name: 'KM', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Fuel_Entry_report_GF = ['oc_vehicle_no', 'oc_date', 'oc_ledger_name', 'oc_category', 'oc_narration1','oc_nooflit','oc_perliter','oc_km', 'oc_amount'];
public Fuel_Entry_Report_Row = [];
get_Fuel_Entry_Report() {
  this.isload = true;
  this.get("Api/Reports/get_Fuel_Entry_Reports?From=" + this.S_From + "&To=" + this.S_To +"&Type=Vehicle Maintanace").subscribe((res: any) => {
    this.Fuel_Entry_Report_Row = JSON.parse(res).record;
    this.isload = false;
  });
}


public Vechile_Wise_Expense_Report_Export = [
  // { Field: 'oc_vehicle_no', Name: 'Vehicle No', Align: '' },
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Supplier', Align: '' },
  { Field: 'oc_category', Name: 'Expense', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public S_Vehicle_No="0";
public Vechile_Wise_Expense_report_GF = ['oc_vehicle_no', 'oc_date', 'oc_ledger_name', 'oc_category', 'oc_narration1', 'oc_amount'];
public Vechile_Wise_Expense_Report_Row = [];
get_Vechile_Wise_Expense_Report() {
  this.isload = true;
  this.get("Api/Reports/get_Vechile_Wise_Expense_Reports?From=" + this.S_From + "&To=" + this.S_To +"&Type=Vehicle Maintanace" + "&VehicleNo=" + this.S_Vehicle_No).subscribe((res: any) => {
    this.Vechile_Wise_Expense_Report_Row = JSON.parse(res).record;
    this.isload = false;
  });
}

public Supplier_Wise_Expense_Report_Export = [
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_invoice_no', Name: 'Bill No', Align: '' },
  { Field: 'oc_vehicle_no', Name: 'Vehicle No', Align: '' },
  { Field: 'oc_category', Name: 'Expense', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public S_Supplier="0";
public Supplier_Wise_Expense_report_GF = ['oc_vehicle_no','oc_invoice_no', 'oc_date', 'oc_ledger_name', 'oc_category', 'oc_narration1', 'oc_amount'];
public Supplier_Wise_Expense_Report_Row = [];
get_Supplier_Wise_Expense_Report() {
  this.isload = true;
  this.get("Api/Reports/get_Supplier_Wise_Expense_Reports?From=" + this.S_From + "&To=" + this.S_To +"&Type=Vehicle Maintanace" + "&Supplier=" + this.S_Supplier).subscribe((res: any) => {
    this.Supplier_Wise_Expense_Report_Row = JSON.parse(res).record;
    this.isload = false;
  });
}

public Expense_Report_Export = [
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_remarks', Name: 'Remarks', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Expense_Report_GF = ['oc_date', 'oc_category', 'oc_ledger_name', 'oc_paymode', 'oc_narration1', 'oc_remarks', 'oc_amount'];
public Expense_Report_Rows = [];
public Expense_Group_Report_Rows = [];
get_Expense_Report() {
  this.isload = true;
  this.Expense_Report_Rows = [];
  this.get("Api/reports/get_Expense_Report?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
    this.Expense_Report_Rows = JSON.parse(res).record;
    this.isload = false;

  //   var category = [...new Set(this.Expense_Report_Rows.map(item => item.oc_category))];
  //   this.Expense_Group_Report_Rows = [];
  //   for (let data of category) {
  //     var Amount_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

  //     this.Expense_Group_Report_Rows.push({ Item_Name: data, Qty: Qty_, Amount: Amount_,Profit:Profit_ ,S_Rate:S_Rate_})
  //   }
   });

}


public Income_Report_Export = [
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_remarks', Name: 'Remarks', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Income_Report_GF = ['oc_date', 'oc_category', 'oc_ledger_name', 'oc_paymode', 'oc_narration1', 'oc_remarks', 'oc_amount'];
public Income_Report_Rows = [];
get_Income_Report() {
  this.isload = true;
  this.Income_Report_Rows = [];
  this.get("Api/reports/get_Income_Report?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
    this.Income_Report_Rows = JSON.parse(res).record;
    this.isload = false;
  });

}

public Ledger_Rpt=[];
onChange_Ledger(data)
{
  this.Ledger_Rpt=[];
  this.Ledger_="0";
  this.Ledger_Rpt=this.get_Group_ledger(data);
}

public Ledger_Wise_Expense_Export = [
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }
];
//public S_Category="";
public Ledger_Wise_Expense_GF = ['oc_date', 'oc_category', 'oc_ledger_name', 'oc_paymode', 'oc_narration1', 'oc_remarks', 'oc_amount'];
public Ledger_Wise_Expense_Rows = [];
get_Ledger_Wise_Expense() {
  this.isload = true;
  this.Ledger_Wise_Expense_Rows = [];
  this.get("Api/reports/get_Ledger_Wise_Expense?From=" + this.S_From + "&To=" + this.S_To + "&Category=" + this.Category + "&Ledger=" + this.Ledger_ ).subscribe((res: any) => {
    this.Ledger_Wise_Expense_Rows = JSON.parse(res).record;
    this.isload = false;
   });

}


public Ledger_Wise_Income_Export = [
  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },
  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
//public S_Category="";
public Ledger_Wise_Income_GF = ['oc_date', 'oc_category', 'oc_ledger_name', 'oc_paymode', 'oc_narration1', 'oc_remarks', 'oc_amount'];
public Ledger_Wise_Income_Rows = [];
get_Ledger_Wise_Income() {
  this.isload = true;
  this.Ledger_Wise_Income_Rows = [];
  this.get("Api/reports/get_Ledger_Wise_Income?From=" + this.S_From + "&To=" + this.S_To + "&Category=" + this.Category + "&Ledger=" + this.Ledger_ ).subscribe((res: any) => {
    this.Ledger_Wise_Income_Rows = JSON.parse(res).record;
    this.isload = false;
   });

}

}
