<div class="row">
    <div class="mshow" style="width: 100%;margin-top: -10px;">&nbsp;</div>
    <select class="select2-option form-control " [(ngModel)]="appservice.Company_ID" name="Company_ID"
                                              #Company_ID="ngModel" (ngModelChange)="appservice.onChange_Company(appservice.Company_ID)" style="width:100%" aria-hidden="true">
                                              <option *ngFor="let data of appservice.Branch_Master_Rows" value={{data.value}}>
                                                  {{data.label}}
                                              </option>
                                          </select>
    <div class="  col-lg-3 col-md-12 col-sm-12" >
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon vshade">
                    <i class="bx bx-user"></i>
                </div>
                <p class="card-category">New Customer</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>

        </div>
    </div>
    <div class="  col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon gshade">
                    <i class="bx bx-user-plus"></i>
                </div>
                <p class="card-category">New Subscribe</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>
        </div>
    </div>
    <div class="  col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon oshade">
                    <i class="bx bxs-wallet-alt"></i>
                </div>
                <p class="card-category">Installment</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>
        </div>
    </div>
    <div class=" col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon bshade">
                    <i class="bx bxl-paypal"></i>
                </div>
                <p class="card-category">Payment Request</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>

        </div>
    </div>


</div>